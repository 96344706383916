body {
    background: linear-gradient(45deg, #f1bd9e, #f3cfb1, #f7e1cc, #fae7e4, #fcf4f1);
    background: -webkit-linear-gradient(45deg, #f1bd9e, #f3cfb1, #f7e1cc, #fae7e4, #fcf4f1);
    background: -moz-linear-gradient(45deg, #f1bd9e, #f3cfb1, #f7e1cc, #fae7e4, #fcf4f1);
    background: -o-linear-gradient(45deg, #f1bd9e, #f3cfb1, #f7e1cc, #fae7e4, #fcf4f1);
}

p, h1, h3 {
    margin: 0;
}

.main-box {
    background: linear-gradient(#FFFBFB, #FFFBFB) padding-box,
    linear-gradient(to bottom, #6C5242, #C69579) border-box;
    border-radius: 10px;
    border: 4px solid transparent;
    max-width: 400px;
    padding: 22px;
}

.avatar {
    width: 4rem;
    border-radius: 100%;
    box-shadow: 0 2px 6px 0 rgba(158, 109, 89, .4);
    margin-bottom: 5pt;
}

.description {
    margin-top: 0.7rem;
}

#details {
    margin-top: 0.3rem;
}

.icon {
    width: 2rem;
    filter: invert(0.1);
    transition: filter 0.2s;
}

.icon:hover {
    filter: invert(0.22);
}

.social {
    margin-top: 0.5rem;
}