body {
  background: -webkit-linear-gradient(45deg, #f1bd9e, #f3cfb1, #f7e1cc, #fae7e4, #fcf4f1);
  background: -moz-linear-gradient(45deg, #f1bd9e, #f3cfb1, #f7e1cc, #fae7e4, #fcf4f1);
  background: -o-linear-gradient(45deg, #f1bd9e, #f3cfb1, #f7e1cc, #fae7e4, #fcf4f1);
}

p, h1, h3 {
  margin: 0;
}

.main-box {
  background: linear-gradient(#fffbfb, #fffbfb) padding-box padding-box, linear-gradient(#6c5242, #c69579) border-box;
  border: 4px solid #0000;
  border-radius: 10px;
  max-width: 400px;
  padding: 22px;
}

.avatar {
  border-radius: 100%;
  width: 4rem;
  margin-bottom: 5pt;
  box-shadow: 0 2px 6px #9e6d5966;
}

.description {
  margin-top: .7rem;
}

#details {
  margin-top: .3rem;
}

.icon {
  filter: invert(.1);
  width: 2rem;
  transition: filter .2s;
}

.icon:hover {
  filter: invert(.22);
}

.social {
  margin-top: .5rem;
}
/*# sourceMappingURL=index.3c172c86.css.map */
